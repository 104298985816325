import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, ogContent, clickToScrollToBlock, scrollToBlock, hashLink } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage } from './functions/form.js'
import { overlayShare, overlayMenu } from './functions/overlays.js'
import { swiperTestimonials, swiperHomeBanner } from  './functions/sliders'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formSuccess, formContact, formJobs } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { removeHoverOnMobile, sizeTitleRelativeToWordLength } from './functions/helper.js'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.scrollToBlock = scrollToBlock

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    overlayShare()
    overlayMenu()
    hashLink({ pathName:'/emplois', hash:'candidature-spontanee', selector:'.js-scroll-to-spontaneous-application', scrollTo:'.js-spontaneous-application', offset:-50 })
    clickToScrollToBlock({ selector: '.js-scroll-to-header', scrollTo: 'header' })
    backendKeyBind()
    SCROLLFIRE.init()
    removeHoverOnMobile()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          clickToScrollToBlock({ selector: 'a[href$=\'#rubriques\']', scrollTo: '.layout__rubrics', offset: -50 })
          clickToScrollToBlock({ selector: '.js-scroll-to-description', scrollTo: '.js-description', offset: -50 })
          clickToScrollToBlock({ selector: '.js-scroll-to-description--dark', scrollTo: '.js-description--dark' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          new Accordions({ scrollToAccordion: false })
          sizeTitleRelativeToWordLength({ selectors: 'h1.js-home-banner-title', caract01: '8', caract02: '10' })
          swiperHomeBanner()
          swiperTestimonials()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          formJobs()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobView',
        beforeEnter() {
          dynamicPackage()
          formsPackage()
          masksPackage()
          formJobs()
          clickToScrollToBlock({ selector: 'a[href$=\'#postuler\']', scrollTo: '.js-application', offset: -50 })
          clickToScrollToBlock({ selector: '.js-scroll-to-description', scrollTo: '.js-description', offset: -50 })
          clickToScrollToBlock({ selector: '.js-scroll-to-description--dark', scrollTo: '.js-description--dark' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          formContact()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: '404View',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: '500View',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      }
    ]
  })
}
