import { OBSERVER } from './../main'

// Retourne si le site est ouvert dans un appareil mobile
export function isMobile() {
  let isMobile = false

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}


// Retourne le offset d'un élément dans la page
export function getElementOffset (options) {
  let box = typeof options.element === 'string' ? document.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}


// Retourne le poids d'un fichier
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


// Retourne si un élément est visible dans le viewport
export function isVisible(element) {
  const rect = element.getBoundingClientRect()

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const viewportWidth = window.innerWidth  || document.documentElement.clientWidth

  const top = rect.top <= viewportHeight
  const right = rect.right >= 0 - viewportWidth
  const bottom = rect.bottom >= 0 - viewportHeight
  const left = rect.left <= viewportWidth

  return top && right && bottom && left
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')){
            var selectorText = styleSheet.rules[ri].selectorText
            var araySelectorText = selectorText.split(',')
            var newSelectorText = ''

            // Garder slm les selectorText no :hover
            for (var i = 0; i < araySelectorText.length; i++){
              if (!araySelectorText[i].match(':hover')){
                newSelectorText += (newSelectorText !== '')? ', ' + araySelectorText[i] : ' ' + araySelectorText[i]
              }
            }

            // Enlver la règle si la seule classe est un :hover
            if (newSelectorText === ''){ styleSheet.deleteRule(ri) }
            else { styleSheet.rules[ri].selectorText = newSelectorText }
          }
        }
      }
    } catch (error) { console.log(error) }
  }
}


// Réduit la taille des H1 s'il comporte trop de caractères
export function sizeTitle(options) {

  // Déclaration des propriétés par défaut
  options.selectors         === undefined ? options.selectors         = 'h1, .h1'                           : options.selectors
  options.caract01          === undefined ? options.caract01          = Infinity                            : options.caract01
  options.caract02          === undefined ? options.caract02          = Infinity                            : options.caract02
  options.caract03          === undefined ? options.caract03          = Infinity                            : options.caract03
  options.classe01          === undefined ? options.classe01          = 'little-smaller'                    : options.classe01
  options.classe02          === undefined ? options.classe02          = 'smaller'                           : options.classe02
  options.classe03          === undefined ? options.classe03          = 'much-smaller'                      : options.classe03

  let i
  let selectors = document.querySelectorAll(options.selectors)
  let textLength

  for(i=0; i<selectors.length; i++) {
    textLength = selectors[i].textContent.length

    if(textLength >= options.caract01 && textLength <= options.caract02) {
      selectors[i].classList.add(options.classe01)
    }
    else if(textLength >= options.caract02 && textLength <= options.caract03) {
      selectors[i].classList.add(options.classe02)
    }
    else if(textLength >= options.caract03) {
      selectors[i].classList.add(options.classe03)
    }
  }
}


// Réduit la taille des H1 si la longueur d'un mot du titre comporte trop de caractères
export function sizeTitleRelativeToWordLength(options) {

  // Déclaration des propriétés par défaut
  options.selectors         === undefined ? options.selectors         = 'h1, .h1'                           : options.selectors
  options.caract01          === undefined ? options.caract01          = Infinity                            : options.caract01
  options.caract02          === undefined ? options.caract02          = Infinity                            : options.caract02
  options.classe01          === undefined ? options.classe01          = 'little-smaller'                    : options.classe01
  options.classe02          === undefined ? options.classe02          = 'smaller'                           : options.classe02

  let i
  let selectors = document.querySelectorAll(options.selectors)
  let text
  let words
  let word
  let wordLength
  let longest
  let cptWord

  for(i=0; i<selectors.length; i++) {
    text = selectors[i].textContent
    words = text.split(' ')
    word = null
    longest = 0

    for (cptWord=0; cptWord< words.length - 1; cptWord++) {
      if (longest < words[cptWord].length) {
        longest = words[cptWord].length
        word = words[cptWord]
      }
    }

    wordLength = word.length
    if(wordLength >= options.caract01 && wordLength <= options.caract02) {
      selectors[i].classList.add(options.classe01)
    }
    else if(wordLength >= options.caract02) {
      selectors[i].classList.add(options.classe02)
    }
  }
}


// Permet de donner la hauteur exact en mobile de 100vh
export function hundredVH() {
  let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  OBSERVER.add({ name:'hundredVH', event:'resize', function:setHeight, target: 'window' })
  OBSERVER.on('hundredVH')
  setHeight()

  function setHeight() {
    if (ww != (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)) {
      ww =  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}
