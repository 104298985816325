import { OBSERVER } from './../main.js'
import Overlay from '../vendor/overlay.js'
import ShareButtons from 'share-buttons/dist/share-buttons.js'

// Fonction gérant l'overlay Share
export function overlayShare() {

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  // Message de confirmation de copie de l'URL ----------------
  function confirmClipboardCopy() {
    alert('L’adresse URL a bien été copiée dans votre presse-papier.')
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    event: 'click',
    target: '.js-share-copy',
    function: confirmClipboardCopy
  })
  OBSERVER.on('confirmClipboardCopy')

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    event: 'click',
    target: '.js-share',
    function: openShareOverlay
  })
  OBSERVER.on('openShareOverlay')

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ')
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'closeShareOverlay',
    event: 'click',
    target: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay
  })
  OBSERVER.on('closeShareOverlay')
}


// Fonction gérant l'overlay Menu
export const overlayMenu = (root = document) => {
  let menu = new Overlay({
    name: 'menu',
    click: { buttons: { toggle: '.js-toggle-overlay-menu', close: '.js-close-overlay-menu' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
      closeOnResize: false
    }
  })

  menu.init()
}