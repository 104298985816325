// Documentation: https://swiperjs.com/swiper-api
import Swiper from 'swiper/bundle'


// ---------------------------------------------------------------------------
// Fonction gérant le swiper des bannières
// ---------------------------------------------------------------------------
export const swiperHomeBanner = () => {
  let totalSlide = $('.js-home-banner-content-slides .swiper-slide:not(.swiper-slide-duplicate)').length

  let homeBannerNamesContentSlider = new Swiper('.js-home-banner-names-content-slides', {
    slidesPerView: 'auto',
    loop: (totalSlide > 1),
    speed: 500,
    spaceBetween: 25,
    slideToClickedSlide: true,
    on: {
      afterInit: function (e) {
        cropSwiperNamesContainer(e)
      },
      beforeTransitionStart: function (e) {
        cropSwiperNamesContainer(e)
      }
    }
  })

  let homeBannerContentSlider = new Swiper('.js-home-banner-content-slides', {
    slidesPerView: 1,
    loop: (totalSlide > 1),
    effect: 'fade',
    speed: 500,
    autoHeight: true,
    navigation: {
      nextEl: '.js-home-banner-content-slides-next',
      prevEl: '.js-home-banner-content-slides-prev',
    }
  })

  homeBannerNamesContentSlider.on('slideChange', function (e) {
    if(e.realIndex !== homeBannerContentSlider.realIndex){
      homeBannerContentSlider.slideToLoop(e.realIndex, 300)
    }
  })

  homeBannerContentSlider.on('slideChange', function (e) {
    if(e.realIndex !== homeBannerNamesContentSlider.realIndex) {
      homeBannerNamesContentSlider.slideToLoop(e.realIndex, 300)
    }
  })

  function cropSwiperNamesContainer(){
    let swiperContainer = document.querySelector('.home-banner__names-container')
    let slideActive = document.querySelector('.home-banner__names-slide.swiper-slide-active')
    let slideNext = document.querySelector('.home-banner__names-slide.swiper-slide-next')
    let spaceBetween = 25
    let swiperContainerWidth = (totalSlide > 1) ? slideActive.offsetWidth + spaceBetween + slideNext.offsetWidth : slideActive.offsetWidth

    swiperContainer.style.maxWidth = swiperContainerWidth + 'px'
  }
}


// ---------------------------------------------------------------------------
// Fonction gérant le swiper des témoignages
// ---------------------------------------------------------------------------
export const swiperTestimonials = () => {
  let testimonialsContentSlider = new Swiper('.js-testimonials-content-slides', {
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: { crossFade: true },
    speed: 500,
    loop: true,
    simulateTouch: true,
    //autoHeight: true,
    pagination: {
      el: '.js-testimonials-content-slides-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.js-testimonials-content-slides-next',
      prevEl: '.js-testimonials-content-slides-prev',
    },
  })

  let homeBannerImagesSlider = new Swiper('.js-testimonials-images-slides', {
    slidesPerView: 1,
    effect: 'fade',
    speed: 500,
    loop: true,
    simulateTouch: true,
  })


  // Sync du texte et image
  testimonialsContentSlider.controller.control = homeBannerImagesSlider
  homeBannerImagesSlider.controller.control = testimonialsContentSlider
}