// Le package à appliquer pour les zones dynamiques
export function dynamicPackage() {
  wrapYtVideo()
  adjustButtonsMargins()
  autoTargetBlank()
  addSpanToButtons()
  addSpanAnimatePointToButtons()
  addSvgToLinks()
  manageImageLinks()
  addClassEmphaseToDiv()
}


// Englobe les iframe de vidéos youtube pour gérer le ratio
function wrapYtVideo() {
  let i,
    youtubeVideosOuterHtml,
    newYoutubeVideosOuterHtml,
    youtubeVideos = document.querySelectorAll('.dynamic iframe[src*="youtube.com"]'),
    youtubeVideosLength = youtubeVideos.length
  
  for(i=0; i<youtubeVideosLength; i++) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml
  }
}


// Ajouter des marges pour les <p> qui contiennent des boutons
function adjustButtonsMargins() {
  let i,
    buttons = document.querySelectorAll('.dynamic p > a.primary-button, .dynamic p > a.secondary-button'),
    buttonsLength = buttons.length

  for(i=0; i<buttonsLength; i++)
    buttons[i].parentNode.classList.add('buttons')

  let buttonsParagraph = document.querySelectorAll('.dynamic p.buttons'),
    buttonsParagraphLength = buttonsParagraph.length

  for(i=0; i<buttonsParagraphLength; i++) {
    if(buttonsParagraph[i].previousElementSibling !== null)
      if(!buttonsParagraph[i].previousElementSibling.classList.contains('buttons'))
        buttonsParagraph[i].classList.add('buttons--first')
    if(buttonsParagraph[i].nextElementSibling !== null)
      if(!buttonsParagraph[i].nextElementSibling.classList.contains('buttons'))
        buttonsParagraph[i].classList.add('buttons--last')
  }
}


// Ajouter un <span> dans les boutons pour l'animation
function addSpanToButtons() {
  let i,
    buttonsInnerHtml,
    newButtonsInnerHtml,
    buttons = document.querySelectorAll('.dynamic .primary-button, .dynamic .secondary-button'),
    buttonsLength = buttons.length

  for(i=0; i<buttonsLength; i++) {
    buttonsInnerHtml = buttons[i].innerHTML
    newButtonsInnerHtml = `<span>${buttonsInnerHtml}</span>`
    buttons[i].innerHTML = newButtonsInnerHtml
  }
}


// Ajouter des <span> dans les boutons pour l'animation du point
function addSpanAnimatePointToButtons() {
  let i,
    buttonsInnerHtml,
    newButtonsInnerHtml,
    buttons = document.querySelectorAll('.dynamic .primary-button, .dynamic .secondary-button'),
    buttonsLength = buttons.length

  for(i=0; i<buttonsLength; i++) {
    buttonsInnerHtml = buttons[i].innerHTML
    newButtonsInnerHtml = '<span class="animate-point"><span></span><span></span></span>' + buttonsInnerHtml
    buttons[i].innerHTML = newButtonsInnerHtml
  }
}


// Ajouter les icones svg pour les boutons et les liens textes
function addSvgToLinks() {
  let themePath = window.config.theme_path
  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"]):not(.primary-button):not(.secondary-button)')

  linksExternal.forEach(function (link) {
    if (!link.querySelector('img')) {
      let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-external`)
      svgElem.appendChild(useElem)
      link.appendChild(svgElem)
    }
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"]:not(.primary-button):not(.secondary-button), .dynamic a[href$=".doc"]:not(.primary-button):not(.secondary-button), .dynamic a[href$=".zip"]:not(.primary-button):not(.secondary-button)')
  linksDocuments.forEach(function (link) {
    if (!link.querySelector('img')) {
      let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-download`)
      svgElem.appendChild(useElem)
      link.appendChild(svgElem)
      link.setAttribute('target', '_blank')
    }
  })
}


// Ajouter les icones svg pour les boutons et les liens textes
function manageImageLinks() {
  let links = document.querySelectorAll('.dynamic a')
  links.forEach(function (link) {
    if (link.querySelector('img')) {
      link.classList.add('link-image')
    }
  })
}


// Mettre des target _blank automatiquement sur les liens externes
function autoTargetBlank() {
  document.querySelectorAll('.dynamic a').forEach(link => {
    link.hostname !== location.hostname && link.setAttribute('target', '_blank')
  })
}


// Ajouter la classe .emphasis pour les blocs containers de l'API Folks
function addClassEmphaseToDiv() {
  let i,
    divs = document.querySelectorAll('.dynamic > div:not(.table-wrapper, .emphasis)'),
    divsLength = divs.length

  for(i=0; i<divsLength; i++) {
    divs[i].removeAttribute('style')
    divs[i].classList.add('emphasis')
  }
}